import Link from 'next/link';

import { routePaths } from '@/core/constants/routes';

import {
  StyledAdditionalText,
  StyledButton,
  StyledContainer,
  StyledSubTitle,
  StyledTitle,
} from './Error.styles';

export const Error = ({ statusCode }: {
  statusCode: number;
}) => (
  <>
    {Number(statusCode) !== 403 && (
      <StyledContainer>
        <StyledTitle>{statusCode}</StyledTitle>
        <StyledSubTitle>
          {statusCode === 404 ?
            'oops... page not found' :
            'An error occurred'}
        </StyledSubTitle>
        <StyledAdditionalText>
          We suggest you go to the homepage while we fix the problem
        </StyledAdditionalText>
        <Link
          href={routePaths.home}
          passHref
        >
          <StyledButton>
            Back to homepage
          </StyledButton>
        </Link>
      </StyledContainer>
    )
  }
    {Number(statusCode) === 403 && (
      <StyledContainer>
        <StyledTitle>{statusCode}</StyledTitle>
        <StyledSubTitle>
          Restricted Access
        </StyledSubTitle>
        <StyledAdditionalText>
          You do not have permission to access this page.
        </StyledAdditionalText>
        <Link
          href={routePaths.home}
          passHref
        >
          <StyledButton>
            Back to homepage
          </StyledButton>
        </Link>
      </StyledContainer>
    )}
  </>
);
