import { NextPageContext } from 'next';

import { Error } from '@/core/components/Error/Error';
import { PageHead } from '@/core/components/PageHead';

const getStatusHeadText = (statusCode: number) => {
  switch (statusCode) {
    case 403:
      return '403 Restricted Access';
    case 404:
      return '404 Page Not Found';
    case 500:
      return '500 Error Occurred';
    default:
      return 'Error';
  }
};

const ErrorPage = ({ statusCode }: {
  statusCode: number;
}) => (
  <>
    <PageHead title={getStatusHeadText(statusCode)} />
    <Error statusCode={statusCode} />
  </>
);

ErrorPage.getInitialProps = ({
  res,
  err,
  query,
}: NextPageContext) => {
  let statusCode: number;

  if (res) {
    statusCode = Number(query.statusCode) || res.statusCode;
  } else {
    statusCode = err ?
      Number(query.statusCode) || err.statusCode || 404 :
      Number(query.statusCode) || 404;
  }

  return { statusCode };
};

export default ErrorPage;
