import styled from 'styled-components';

import { fontWeight } from '@/core/styles/mixins/typography';
import { spacing } from '@/core/styles/mixins/spacing';
import { BodyText } from '@/core/components/typography';
import { Button } from '@/core/components/Button';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${spacing(16)};
`;

export const StyledTitle = styled.h1`
  ${fontWeight('bold')}

  margin: 0 0 ${spacing(3)};
  color: ${({ theme }) => theme.colors.vibrantBlue};
  font-size: 160px;
  line-height: 64px;
`;

export const StyledSubTitle = styled.h2`
  ${fontWeight('light')}

  margin-bottom: ${spacing(3)};
  font-size: 42px;
  line-height: 50px;
`;

export const StyledAdditionalText = styled(BodyText)`
  margin-bottom: ${spacing(9)};
  color: ${({ theme }) => theme.semanticColors.text.secondary};
`;

export const StyledButton = styled(Button)`
  justify-content: center;
`;
